import { LayoutService } from "./services/layout.service";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { SecurityService, HttpBareClient } from "@ng-darwin/security";
import { LoggerService } from "@ng-darwin/logger";
import { DataService } from "./services/data.service";

@Component({
  selector: "prescp-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  alertShow = false;
  headerShow = false;
  footerShow = false;
  alertOptions = {};

  claseBackground = "ps--full-image-container";

  countDown = 0;
  response: any;

  /**
   * Several services are injected into the constructor for the examples
   * The only mandatory service is securityService if you want the token to be managed by Darwin
   */
  constructor(
    private readonly securityService: SecurityService,
    private readonly loggerService: LoggerService,
    private readonly httpBareClient: HttpBareClient,
    public layoutService: LayoutService,
    public dataService: DataService,
    private readonly cdRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    // THIS TWO LINES OF CODE ARE MANDATORY to manage the session and token:
    // - Subscription to session events (aboutToTimeout, timeout, resumed)
    // - Initialization of security service to manage the session and token
    // this._subcribeToSessionEvents();

    //  VERY IMPORTANT!!!
    //  PUT YOUR CODE HERE, AFTER SECURITY INITIALIZE PROMISE HAS BEEN FULLFILLED
    // **************************************************************************
    // this._logMessages();
    this.layoutSubscribers();
    this.backgroundMatch("");
  }

  ngAfterViewChecked(){
    this.cdRef.detectChanges();
  }

  backgroundMatch(type: string): void {
    this.dataService.background$.subscribe((response: any) => {
      switch (response) {
        case "correos":
          this.claseBackground = "ps--full-image-container-correos";
          break;
        case "mapfre":
          this.claseBackground = "ps--full-image-container";
          break;
        case "sinfondo":
          this.claseBackground = "ps--full-container";
          break;
        case "comerciales":
          this.claseBackground = "ps--full-image-container-comerciales"
          break;
        case "bp":
          this.claseBackground = "ps--full-image-container-comerciales"
          break;
        default:
          this.claseBackground = "ps--full-image-container";
          break;
      }
      this.cdRef.detectChanges();
    });
  }

  layoutSubscribers() {
    this.layoutService.alertOptions$.subscribe((options) => {
      this.alertOptions = options;
    });
    this.layoutService.alertIsShowed$.subscribe((show) => {
      this.alertShow = show;
    });
    this.layoutService.headerIsShowed$.subscribe((show) => {
      setTimeout(() => (this.headerShow = show));
    });
    this.layoutService.footerIsShowed$.subscribe((show) => {
      this.footerShow = show;
    });
  }

  /**
   * Method for a button click event inside the view
   */
  getRequestThroughHttpBareClient() {
    // Important: Note that this request through httpBareClientService has no interceptors. It's a bare request
    this.httpBareClient
      .get("/api/alive", { responseType: "text" })
      .subscribe((value) => {
        console.log("alive response:", value);
        this.response = value;
      });
  }

  /**
   * Method for a button click event inside the view
   */
  postRequestThroughLoggerService() {
    // Important: Note this request always have common headers if Security module has been loaded,
    // but only have auth header if 'logger.isSecure' property is true
    this.loggerService
      .logError({
        log: "my message to be logged",
        component: "mycomponent",
      })
      .then(() => {
        this.response =
          "Log enviado a fake Data Base. Se ha creado un nuevo registro en el fichero db.json";
      });
  }

  /**
   * Method for close session and clean credentials
   */
  closeSession() {
    this.securityService.killSession();
    this.response =
      "La sessión ha sido cerrada y las credenciales se han eliminado correctamente";
  }
}
