import * as tslib_1 from "tslib";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ElementRef, OnInit } from "@angular/core";
import { ROUTE_PATH } from "./../../../environments/environment";
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, securityService, _headerService, sanitizer, layoutService, userService, dataService) {
        this.router = router;
        this.securityService = securityService;
        this._headerService = _headerService;
        this.sanitizer = sanitizer;
        this.layoutService = layoutService;
        this.userService = userService;
        this.dataService = dataService;
        this.nombre = "Cargando...";
        this.contrato = "Cargando...";
        this.pass = "**********";
        this.showUserProfile = false;
        this.inputTextInvalid = true;
        // Actuacion
        this.actuacion = sessionStorage.getItem("action");
        this.editado = false;
        this.actuacionStorage = this.actuacion;
        this.contratoHeader = sessionStorage.getItem("contratoHeader");
        this.nombreHeader = sessionStorage.getItem("nombreHader");
        this.nombreUser = sessionStorage.getItem("nUser");
        this.telfUsuario = sessionStorage.getItem("telefono");
        this.emailUsuario = sessionStorage.getItem("email");
        this.documentUser = sessionStorage.getItem("doc");
        this.ofi = sessionStorage.getItem("oficina");
        this.oficinaMap = sessionStorage.getItem("oficinaMapfre");
        this.claveMapfre = sessionStorage.getItem("claveMp");
        this.oficinaMa = sessionStorage.getItem("oficinaM");
        // Logos
        this.logo1 = "";
        this.logo2 = "";
        this.areaPersonalForm = new FormGroup({
            telefonoP: new FormControl("", [
                Validators.required,
                Validators.minLength(9),
                Validators.maxLength(11),
                Validators.pattern(/^\d+$/),
            ]),
        });
        // mensajes
        this.telefonoOk = "Teléfono actualizado correctamente";
        this.telefonoError = "Error al insertar el teléfono";
        this.telefonoVacio = "Error campo vacío";
        this.telefonoConLetras = "Error no se pueden introducir letras";
        this.errorLongitud = "El teléfono debe tener una longitud de 9 a 11 dígitos";
        this.tipoPrescriptor = sessionStorage.getItem('tipoPresc');
    }
    /**
     * metodo
     * ngOnInit
     */
    HeaderComponent.prototype.ngOnInit = function () {
        this.getLogos();
        this.dataPres();
    };
    HeaderComponent.prototype.dataPres = function () {
        this.contrato = "Sin datos";
        this.nombre = "Oficina no definida";
        this.actuacion = "";
        this.telf = "Sin datos";
        this.email = "Sin datos";
        this.doc = "Sin datos";
        this.oficinaSantander = "Sin datos";
        this.oficinaMapfre = "Sin datos";
        this.claveMapf = "Sin datos";
        this.nUser = "";
        if (this.contratoHeader) {
            this.contrato = this.contratoHeader;
        }
        if (this.nombreHeader) {
            this.nombre = this.nombreHeader;
        }
        if (this.actuacionStorage) {
            this.actuacion = this.actuacionStorage;
        }
        if (this.telfUsuario) {
            this.telf = this.telfUsuario;
        }
        if (this.emailUsuario) {
            this.email = this.emailUsuario;
        }
        if (this.documentUser) {
            this.doc = this.documentUser;
        }
        if (this.ofi) {
            this.oficinaSantander = this.ofi;
        }
        if (this.oficinaMa) {
            this.oficinaMapfre = this.oficinaMa;
        }
        if (this.claveMapfre) {
            this.claveMapf = this.claveMapfre;
        }
        if (this.nombreUser) {
            this.nUser = this.nombreUser;
        }
    };
    HeaderComponent.prototype.routerGoTo = function (item) {
        switch (item) {
            case "home":
                this.comprobarRedireccionamientoHome();
                break;
            case "seguimiento":
                this.router.navigate([ROUTE_PATH.seguimiento]);
                this.dataService.setBackground("sinfondo");
                break;
            case "factura":
                this.router.navigate([ROUTE_PATH.factura]);
                break;
            case "factura2":
                this.router.navigate([ROUTE_PATH.factura]);
                this.dataService.setBackground("sinfondo");
                break;
            case "circuito":
                this.router.navigate([ROUTE_PATH.circuito]);
                break;
            case "doc":
                this.router.navigate([ROUTE_PATH.documentos]);
                break;
            case "news":
                this.router.navigate([ROUTE_PATH.noticias]);
                break;
            case "logout":
                sessionStorage.clear();
                this.layoutService.hideFooter();
                this.securityService.killSession();
                this.router.navigate([ROUTE_PATH.login]);
                break;
            default:
                break;
        }
    };
    HeaderComponent.prototype.comprobarRedireccionamientoHome = function () {
        (this.actuacion === "19" ||
            this.actuacion === "20" ||
            this.actuacion === "21") && !this.checkPrescComGenerico() && !this.checkPrescBancaPrivada()
            ? this.router.navigate([ROUTE_PATH.inicioProductos])
            : this.router.navigate([ROUTE_PATH.inicioPcomerciales]);
        if (this.checkPrescComGenerico()) {
            this.router.navigate([ROUTE_PATH.inicioProductos]);
        }
        if (this.checkPrescBancaPrivada()) {
            this.router.navigate([ROUTE_PATH.inicioProductos]);
        }
    };
    HeaderComponent.prototype.getLogos = function () {
        var _this = this;
        if (!this.checkPrescBancaPrivada()) {
            if (this.actuacion !== "21") {
                this._headerService
                    .getLogos(this.actuacion)
                    .subscribe(function (response) {
                    if (response.logo1) {
                        _this.logo1 = _this.transform(response.logo1);
                    }
                    if (response.logo2) {
                        _this.logo2 = _this.transform(response.logo2);
                    }
                });
            }
        }
    };
    HeaderComponent.prototype.transform = function (img) {
        return this.sanitizer.bypassSecurityTrustResourceUrl("data:image/jpeg;base64," + img);
    };
    HeaderComponent.prototype.editProfile = function () {
        this.edit = document.getElementById("editar");
        if (this.editado === true) {
            this.editado = false;
            this.edit.innerHTML = "Editar";
            this.areaPersonalForm.reset();
        }
        else {
            this.editado = true;
            this.edit.innerHTML = "Volver";
        }
    };
    HeaderComponent.prototype.guardarAreaP = function () {
        var _this = this;
        this.editado = false;
        this.edit.innerHTML = "Editar";
        var telefono = this.areaPersonalForm.value.telefonoP;
        var regexNum = /[^0-9]/;
        if (telefono === null || telefono === "") {
            this.enviarMensajeError(this.telefonoVacio);
        }
        else if (telefono.match(regexNum)) {
            this.enviarMensajeError(this.telefonoConLetras);
        }
        else {
            if (telefono.length > 8 && telefono.length < 12) {
                this._headerService.setAreaPersonal(telefono).subscribe(function (response) {
                    _this.enviarMensajeOk(_this.telefonoOk);
                    _this.getPhone();
                }, function (err) {
                    _this.enviarMensajeError(_this.telefonoError);
                });
            }
            else {
                this.enviarMensajeError(this.errorLongitud);
            }
        }
        this.areaPersonalForm.reset();
    };
    /**
     * getPhone.
     */
    HeaderComponent.prototype.getPhone = function () {
        var _this = this;
        this.userService.getContactData().subscribe(function (response) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.telf = response.methodResult.telefono;
                sessionStorage.setItem("telefono", this.telf);
                return [2 /*return*/];
            });
        }); });
    };
    HeaderComponent.prototype.navegarCambiarPass = function () {
        this.layoutService.hideHeader();
        sessionStorage.clear();
        this.securityService.killSession();
        this.router.navigate([ROUTE_PATH.recoveryPass]);
    };
    HeaderComponent.prototype.navegarCambiarPassWToken = function () {
        this.layoutService.hideHeader();
        this.layoutService.showResetPass();
        this.router.navigate([ROUTE_PATH.login]);
    };
    /**
     * EnviarMensajeOk
     */
    HeaderComponent.prototype.enviarMensajeOk = function (mensaje) {
        this.layoutService.showAlert({
            textDescription: mensaje,
            textBtnClose: "De acuerdo",
            classIcon: "icon-success",
        });
    };
    /**
     * EnviarMensajeError
     */
    HeaderComponent.prototype.enviarMensajeError = function (mensaje) {
        this.layoutService.showAlert({
            textDescription: mensaje,
            textBtnClose: "De acuerdo",
            classIcon: "icon-error",
        });
    };
    HeaderComponent.prototype.showProfile = function () {
        this.showUserProfile = !this.showUserProfile;
        this.editado = false;
        this.areaPersonalForm.reset();
    };
    HeaderComponent.prototype.checkPrescComGenerico = function () {
        if (this.tipoPrescriptor === 'comercial') {
            return true;
        }
        else {
            return false;
        }
    };
    HeaderComponent.prototype.checkPrescBancaPrivada = function () {
        if (this.tipoPrescriptor === 'privada') {
            return true;
        }
        else {
            return false;
        }
    };
    return HeaderComponent;
}());
export { HeaderComponent };
